const timesByActivity = {
  sala: {
    mon: [{start_time: '06:00', end_time: '13:00'}, {start_time: '15:00', end_time: '22:00'}],
    tu: [{start_time: '06:00', end_time: '13:00'}, {start_time: '15:00', end_time: '22:00'}],
    wed: [{start_time: '06:00', end_time: '13:00'}, {start_time: '15:00', end_time: '22:00'}],
    th: [{start_time: '06:00', end_time: '13:00'}, {start_time: '15:00', end_time: '22:00'}],
    fri: [{start_time: '06:00', end_time: '13:00'}, {start_time: '15:00', end_time: '22:00'}],
    sat: [{start_time: '09:00', end_time: '14:00'}],
    sun: [],
  },
  funcional: {
    mon: [],
    tu: [{start_time: '18:00', end_time: '19:00'}, {start_time: '19:00', end_time: '20:00'}],
    wed: [],
    th: [{start_time: '19:00', end_time: '20:00'}],
    fri: [{start_time: '18:00', end_time: '19:00'}],
    sat: [],
    sun: [],
  },
  funcional_adolescente: {
    mon: [{start_time: '17:00', end_time: '18:00'}],
    tu: [],
    wed: [{start_time: '17:00', end_time: '18:00'}],
    th: [],
    fri: [{start_time: '17:00', end_time: '18:00'}],
    sat: [],
    sun: [],
  },
  pilates: {
    mon: [{start_time: '08:30', end_time: '09:30'}],
    tu: [{start_time: '08:30', end_time: '09:30'}, {start_time: '20:00', end_time: '21:00'}],
    wed: [{start_time: '08:30', end_time: '09:30'}],
    th: [{start_time: '08:30', end_time: '09:30'}, {start_time: '18:00', end_time: '19:00'}],
    fri: [{start_time: '09:00', end_time: '10:00'}, {start_time: '20:00', end_time: '21:00'}],
    sat: [],
    sun: [],
  },
  indoor_cycling: {
    mon: [{start_time: '21:00', end_time: '22:00'}],
    tu: [{start_time: '20:00', end_time: '21:00'}],
    wed: [{start_time: '19:00', end_time: '20:00'}],
    th: [],
    fri: [{start_time: '20:00', end_time: '21:00'}],
    sat: [],
    sun: [],
  },
  funcional_bike: {
    mon: [{start_time: '18:00', end_time: '19:00'}],
    tu: [],
    wed: [{start_time: '07:30', end_time: '08:30'}, {start_time: '18:00', end_time: '19:00'}],
    th: [{start_time: '20:00', end_time: '21:00'}],
    fri: [],
    sat: [],
    sun: [],
  },
  hiit: {
    mon: [{start_time: '19:00', end_time: '20:00'}],
    tu: [],
    wed: [{start_time: '19:00', end_time: '20:00'}],
    th: [],
    fri: [{start_time: '19:00', end_time: '20:00'}],
    sat: [],
    sun: [],
  },
  yoga: {
    mon: [{start_time: '10:00', end_time: '11:00'}],
    tu: [],
    wed: [],
    th: [],
    fri: [{start_time: '10:00', end_time: '11:00'}],
    sat: [],
    sun: [],
  },
  yoga_niños: {
    mon: [],
    tu: [],
    wed: [],
    th: [{start_time: '18:00', end_time: '19:00'}],
    fri: [],
    sat: [],
    sun: [],
  },
  gimnasia_regenerativa: {
    mon: [],
    tu: [{start_time: '17:00', end_time: '18:00'}],
    wed: [],
    th: [{start_time: '17:00', end_time: '18:00'}],
    fri: [],
    sat: [],
    sun: [],
  },
}

export default timesByActivity;