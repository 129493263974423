const timesByActivity = {
  handball: {
    mon: [],
    tu: [{start_time: '21:30', end_time: '22:45'}],
    wed: [],
    th: [{start_time: '21:30', end_time: '22:45'}],
    fri: [],
    sat: [],
    sun: [],
  },
}

export default timesByActivity;